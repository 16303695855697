
import { CurrentView } from '~/store/building/-constants'
import { smoothScrollToTargetId } from '~/helpers/util'
import baseConstants from '~/store/base/-constants'

export default {
  name: 'SectionBuilding',
  props: {
    data: {
      required: true,
      type: Object || Array,
      default: function () {
        return {
          backgroundLoader: '',
          disclaimer: '',
          contentLoader: [],
          matterportSpace: []
        }
      }
    },
    orderNo: {
      required: true,
      type: Number,
      default: 0
    },
    hash: {
      required: true,
      type: String,
      default: ''
    }
  },
  data() {
    return {
      windowHeight: typeof window !== 'undefined' ? window.innerHeight : 0,
      headerHeight: 0,
      isMobileView: typeof window !== 'undefined' ? window.innerWidth < 1200 : false,
    }
  },
  computed: {
    isMobileMenuOpen() {
      return this.$store.state.base?.mobileAvailabilityMenuOpen
    },
    backgroundLoaderUrl() {
      return this.data?.backgroundLoader
    },
    requestedSpaces() {
      return this.$store.state.requestOffer.spaces
    },
    totalSpace() {
      let total = 0
      this.requestedSpaces.forEach((s) => {
        total += s.sqm
      })
      return total
    },
    unitOfMeasure() {
      return this.$store.state.base?.meta?.generalConfig?.unitOfMeasure
    },
    unitOfMeasureString() {
      return this.$store.state.base?.meta?.generalConfig?.unitOfMeasure === 1 ? 'sqft' : 'sqm'
    },
    activeProject() {
      return this.$store.state.project.activeProject
    },
    projectFeatures() {
      return this.$store.getters?.featuresForProject(this.activeProject);
    },
    disableList() {
      return this.projectFeatures?.disableMyList;
    },
    isFloorView() {
      return this.$store.state.building.currentView === CurrentView.FLOOR
    },
    loadingErrorOccurred() {
      return this.$store.state.building.loadingErrorOccurred
    },
    contentLoaderData() {
      return this.data?.contentLoader
    },
    videosLoader() {
      return this.data?.videosLoader || []
    },
    spacesCounter() {
      return this.$store.state.requestOffer.spaces.length
    },
    guidedTourModeEnabled() {
      return this.$store.state.guidedTour.guidedTourModeEnabled
    },
    shouldOnlyDisplay3dSection() {
      return this.guidedTourModeEnabled
    },
    availableTours() {
      return this.$store.state.guidedTour.availableTours || []
    },
    hasGuidedTours() {
      return this.availableTours.length > 0
    },
  },
  mounted() {
    window.addEventListener('resize', this.resizeListener)
    this.headerHeight = document.getElementById('header-project')
      ? document.getElementById('header-project').clientHeight
      : 0
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeListener)
  },
  methods: {
    goToNextSection() {
      const sections = this.$parent.$parent.sections
      const targetSection = sections[this.orderNo]
      const targetString = targetSection.hashAnchor
      if (sections && sections.length > 1) {
        smoothScrollToTargetId(`#${targetString}`)
      }
    },
    resizeListener(e) {
      this.windowHeight = window.innerHeight
      this.headerHeight = document.getElementById('header-project')
        ? document.getElementById('header-project').clientHeight
        : 0
      this.isMobileView = typeof window !== 'undefined' ? window.innerWidth < 1200 : false
    },
    toggleEmbedSidebar() {
      this.$store.dispatch(baseConstants.withNamespace(baseConstants.action.TOGGLE_MOBILE_AVAILABILITY_MENU))
      this.$store.dispatch(baseConstants.withNamespace(baseConstants.action.CHANGE_SIDEBAR_STATE), false)
    },
    toggleCartSidebar() {
      this.$store.dispatch(baseConstants.withNamespace(baseConstants.action.CHANGE_SIDEBAR_STATE), false)
      this.$store.dispatch(baseConstants.withNamespace(baseConstants.action.CHANGE_MOBILE_AVAILABILITY_MENU), false)
    },
    handleCloseGuidedTourPresentation() {
      this.$refs['guided-tour-presentation-drawer']?.closeDrawer()
    }
  }
}
